import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// components
import LoadingScreen from '../components/loading-screen';
//
import { PATH_PAGE } from '../routes/paths';
import { useAuthContext } from './useAuthContext';

// ----------------------------------------------------------------------

AdminGuard.propTypes = {
  children: PropTypes.node,
};

export default function AdminGuard({ children }) {
  const { isInitialized, user, logout } = useAuthContext();

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState(null);
  
  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (user.role !== 'admin') {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    logout();
    return <Navigate to={PATH_PAGE.comingSoon} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
