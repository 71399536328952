import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback } from 'react';
import { initializeApp } from 'firebase/app';
import * as cheerio from 'cheerio';

import {
  currentUser,
  getAuth,
  getIdTokenResult,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { 
  getFirestore, 
  collection, 
  doc, 
  addDoc, 
  getDoc, 
  getDocs, 
  query, 
  setDoc, 
  where, 
  updateDoc,
  serverTimestamp 
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAnalytics, logEvent } from 'firebase/analytics';
// config
import { FIREBASE_API } from '../config';
// external utils
import { paramCase } from 'param-case';
import { v4 as uuid } from 'uuid';

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);

const analytics = getAnalytics(firebaseApp);

const AUTH = getAuth(firebaseApp);

const DB = getFirestore(firebaseApp);

const GOOGLE_PROVIDER = new GoogleAuthProvider();

const GITHUB_PROVIDER = new GithubAuthProvider();

const TWITTER_PROVIDER = new TwitterAuthProvider();

// ----------------------------------------------------------------------

const collectionCategories = collection(DB, 'categories');
const collectionElements = collection(DB, 'elements');
const collectionJourneys = collection(DB, 'journeys');
const collectionProjects = collection(DB, 'projects');
const collectionScreenshots = collection(DB, 'screenshots');
const collectionVideos = collection(DB, 'videos');
const collectionBlogPosts = collection(DB, 'blogposts');
const collectionDiagrams = collection(DB, 'diagrams');

// ----------------------------------------------------------------------

// HELPER FUNCTIONS

const replaceUnderscores = (str) => {
  return str.replace(/_/g, " ");
};

const removeExtension = (str) => {
  return str.replace(/\.[^/.]+$/, "");
};

const getUnixTimestamp = (file) => {
  const fileCreationDate = new Date(file.lastModified);
  const unixTimestamp = Math.floor(fileCreationDate.getTime() / 1000);
  return unixTimestamp;
};
// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          const userRef = doc(DB, 'users', user.uid);

          const docSnap = await getDoc(userRef);

          const profile = docSnap.data();

          user.getIdTokenResult().then((idTokenResult) => {
            if (idTokenResult.claims.admin) {
              dispatch({
                type: 'INITIAL',
                payload: {
                  isAuthenticated: true,
                  user: {
                    ...user,
                    ...profile,
                    role: 'admin',
                  },
                },
              });
            } else {
              dispatch({
                type: 'INITIAL',
                payload: {
                  isAuthenticated: true,
                  user: {
                    ...user,
                    ...profile,
                    role: 'user',
                  },
                },
              });
            }
          })

          

        } else {
          dispatch({
            type: 'INITIAL',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOG IN
  const login = (email, password) => signInWithEmailAndPassword(AUTH, email, password);

  const loginWithGoogle = () => signInWithPopup(AUTH, GOOGLE_PROVIDER);

  const loginWithGithub = async () => signInWithPopup(AUTH, GITHUB_PROVIDER);

  const loginWithTwitter = async () => signInWithPopup(AUTH, TWITTER_PROVIDER);

  // SIGN UP
  const register = (email, password, firstName, lastName) =>
    createUserWithEmailAndPassword(AUTH, email, password).then(async (res) => {
      const userRef = doc(collection(DB, 'users'), res.user?.uid);

      await setDoc(userRef, {
        uid: res.user?.uid,
        email,
        displayName: `${firstName} ${lastName}`,
      });
    });

  // LOG OUT
  const logout = () => signOut(AUTH);

  // GET DATA FORM DB
  const getDocumentFromDB = async (collectionName, docName) => {
    return await getDoc(doc(DB, collectionName, docName));
  }

  const getDataFromDB = async (collectionName) => {
    return await getDocs(collection(DB, collectionName));
  }

  const queryDataFromDB = async (collectionName, key, comparator, value) => {
    const q = query(collection(DB, collectionName), where(key, comparator, value));
    return await getDocs(q);
  }

  // STORE DATA IN DB
  const addNewProjectToDB = async (category, colour, logo, name, description, networks, links) => {
    await addDoc(collectionProjects, {
      category,
      colour,
      logo,
      name,
      description,
      networks,
      links,
      updatedAt: serverTimestamp()
    })
    .then(() => {
      alert('New product added to the database')
    })
    .catch((err) => {
      alert(err.message)
    });
  };

  const addNewBlockchainToDB = async (colour, logo, name, description, links) => {
    const docRef = doc(DB, 'attributes', 'yM8dMB0KhwUmiuoV484u');
    const newBlockchainId = uuid();

    const newBlockchain = {colour, logo, name, description, links};

    const data = {
      [`blockchains.${newBlockchainId}`]: newBlockchain
    }

    await updateDoc(docRef, data)
    .then(() => {
      alert('New blockchain added to the database')
    })
    .catch((err) => {
      alert(err.message)
    });
  };

  const addNewScreenshotToDB = async (blockchain, elements, journey, name, screenName, platform, project, timeStamp, urlJpg, version) => {
    await addDoc(collectionScreenshots, {
      blockchain,
      elements,
      journey,
      name,
      screenName,
      platform,
      project,
      timeStamp,
      urlJpg,
      version, 
      updatedAt: serverTimestamp()
    })
    .then(() => {
      alert('New screenshot added')
    })
    .catch((err) => {
      alert(err.message)
    });
  };

  const addNewVideoToDB = async (
    blockchain, 
    duration, 
    elements, 
    journey, 
    name, 
    screenName, 
    platform, 
    project, 
    timeStamp, 
    urlVideoWebm,
    urlVideoMp4, 
    urlThumbnail, 
    version,
    videoTimestamps
    ) => {
    await addDoc(collectionVideos, {
      blockchain,
      duration,
      elements,
      journey,
      name,
      screenName,
      platform,
      project,
      timeStamp,
      urlVideoWebm,
      urlVideoMp4, 
      urlThumbnail,
      version, 
      videoTimestamps,
      updatedAt: serverTimestamp()
    })
    .then(() => {
      console.log('New video added');
    })
    .catch((err) => {
      console.log(err.message);
    });
  };

  const addNewDiagramToDB = async(diagram, platform, project, version, imageBlob) => {

    // Update the timestamp for updatedAt
    const updatedAt = serverTimestamp();

    const screenshotsRef = ref(storage, `images/${project}-diagram-${Date.now()}`);
    uploadBytes(screenshotsRef, imageBlob).then((snapshot) => {
      console.log('Diagram cover uploaded!');
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        console.log(downloadURL);
        let urlThumbnail = downloadURL;
        addDoc(collectionDiagrams, {diagram, platform, project, version, updatedAt, urlThumbnail})
        .then(() => {
          alert('New diagram added');
        }).catch((err) => {
          alert(err.message);
        })
      });
    });
  }

  // UPDATE DATA IN DB
  const updateProjectBoard = async (projectName, data) => {
    const docRef = doc(DB, 'projects', projectName);
    console.log(docRef);

    // Update the timestamp for updatedAt
    data.updatedAt = serverTimestamp();

    await updateDoc(docRef, data);
  }

  const updateCounters = async (projectName, data) => {
    const q = query(collection(DB, 'projects'), where('name', '==', replaceUnderscores(projectName)));
    let docsFromDB = await getDocs(q);

    // Update the timestamp for updatedAt
    data.updatedAt = serverTimestamp();

    docsFromDB.forEach((doc) => {
      const docRef = doc.ref;
      updateDoc(docRef, data);
    })
  }

  const updateProductDetailsInDB = async(productName, data) => {
    const docRef = doc(DB, 'projects', productName);
    console.log('This is updateProductDetails');
    console.log(docRef);
    console.log(data);
    data.links = [];
    if (data.urlWebsite.length > 0) {
      data.links.push({name: 'website', url: data.urlWebsite});
    }
    if (data.urlTwitter.length > 0) {
      data.links.push({name: 'twitter', url: data.urlTwitter});
    }
    if (data.urlDiscord.length > 0) {
      data.links.push({name: 'discord', url: data.urlDiscord});
    }

    // Update the timestamp for updatedAt
    data.updatedAt = serverTimestamp();
    
    if (typeof data.logo === 'string') {
      console.log('Do not update the logo');
      console.log(data);
      await updateDoc(docRef, data);
    } else {
      console.log('update the logo');
      const logosRef = ref(storage, `logos/${data.logo.name}`);
      uploadBytes(logosRef, data.logo).then((snapshot) => {
        console.log('Product details updated!');
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          data.logo = downloadURL;
          console.log(data);
          updateDoc(docRef, data);
        })
      })
    }
  }

  const updateVideoDetailsInDB = async(videoId, data) => {
    const docRef = doc(DB, 'videos', videoId);

    // Update the timestamp for updatedAt
    data.updatedAt = serverTimestamp();

    await updateDoc(docRef, data);
    console.log('Video Details Updated!');
  }

  const updateScreenshotOrder = async (screenshotName, data) => {
    const docRef = doc(DB, 'screenshots', screenshotName);

    // Update the timestamp for updatedAt
    data.updatedAt = serverTimestamp();

    await updateDoc(docRef, data);
  }

  // STORE AND RETRIEVE FILE IN STORAGE
  const storage = getStorage(firebaseApp);

  const uploadLogo = async (data) => {
    const logosRef = ref(storage, `logos/${data.logo.name}`);
    uploadBytes(logosRef, data.logo).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        let links = [
          {
            name: 'website',
            url: data.urlWebsite
          },
          {
            name: 'twitter',
            url: data.urlTwitter
          },
          {
            name: 'discord',
            url: data.urlDiscord
          },
        ]
        addNewProjectToDB(data.category, data.colour, downloadURL, data.name, data.description, data.networks, links);
      })
    })
  }

  const createBlockchainInDB = async (data) => {
    const logosRef = ref(storage, `logos/${data.logo.name}`);
    uploadBytes(logosRef, data.logo).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        let links = [
          {
            name: 'website',
            url: data.urlWebsite
          },
          {
            name: 'twitter',
            url: data.urlTwitter
          },
          {
            name: 'discord',
            url: data.urlDiscord
          },
        ]
        addNewBlockchainToDB(data.colour, downloadURL, data.name, data.description, links);
      })
    })
  }
  
  const getImageUrlFromDB = async (folder, fileName) => {
    return (await getDownloadURL(ref(storage, `${folder}/${fileName}`)));
  }

  const uploadVideo = (videoFile, thumbnailFile, data) => {
    const videoRef = ref(storage, `videos/${videoFile.name}`);
    const tempVideoElement = document.createElement("video");
    tempVideoElement.src = URL.createObjectURL(videoFile);
    tempVideoElement.addEventListener("loadedmetadata", () => {
      const duration = tempVideoElement.duration;
      console.log("Video duration:", duration);
  
      uploadBytes(videoRef, videoFile).then((snapshot) => {
        console.log("Video uploaded!");
        getDownloadURL(snapshot.ref).then((downloadURLVideo) => {
          console.log(downloadURLVideo);
          const thumbnailRef = ref(storage, `thumbnails/${videoFile.name}`);
          uploadBytes(thumbnailRef, thumbnailFile).then((snapshot) => {
            console.log("Thumbnail uploaded!");
            getDownloadURL(snapshot.ref).then((downloadURLThumbnail) => {
              console.log(downloadURLThumbnail);
              addNewVideoToDB(
                data.blockchain,
                duration,
                data.elements,
                data.journey,
                videoFile.name,
                data.screenName,
                data.platform,
                data.project,
                data.urlVideo.lastModified,
                downloadURLVideo,
                downloadURLThumbnail,
                data.version,
                data.videoTimestamps,
              );
            });
          });
        });
      });
    });
  };

  /** 
  const uploadVideosBulk = async (files) => {
    const filesArray = Array.from(files);
    
    // Filter video and cover image files
    const videoFiles = filesArray.filter((file) => file.type.startsWith('video/'));
    const thumbnailFiles = filesArray.filter((file) => file.type.startsWith('image/'));
    const jsonFiles = filesArray.filter((file) => file.type === 'application/json');
    
    // Split video files by format
    const webmFiles = videoFiles.filter((file) => file.name.endsWith('.webm'));
    const mp4Files = videoFiles.filter((file) => file.name.endsWith('.mp4'));
    
    for (let i = 0; i < webmFiles.length; i++) {
      const webmFile = webmFiles[i];
      const mp4File = mp4Files[i];

      // Remove the file extension to match the thumbnail file name
      const videoFileNameWithoutExtension = webmFile.name.split('.').slice(0, -1).join('.');
  
      // Find the corresponding thumbnail file based on the file name
      const thumbnailFile = thumbnailFiles.find((imageFile) =>
        imageFile.name.startsWith(videoFileNameWithoutExtension)
      );
      if (!thumbnailFile) {
        console.error(`Thumbnail not found for ${webmFile.name}`);
        continue;
      }

      const jsonFile = jsonFiles.find((imageFile) =>
        imageFile.name.startsWith(videoFileNameWithoutExtension)
      );
      if (!jsonFile) {
        console.error(`JSON not found for ${webmFile.name}`);
        continue;
      }

      let videoTimestamps = [];

      if (jsonFile) {
        const jsonContent = await jsonFile.text();
        const jsonData = JSON.parse(jsonContent);
        videoTimestamps = jsonData.videoTimestamps;
        console.log(videoTimestamps);
      }
  
      const [
        project_name,
        journey_type,
        journey_name,
        blockchain_name,
        platform,
        version,
        timestamp
      ] = videoFileNameWithoutExtension.split('-');
  
      const webmRef = ref(storage, `videos/${webmFile.name}`);
      const mp4Ref = ref(storage, `videos/${mp4File.name}`);

      const tempVideoElement = document.createElement("video");
      tempVideoElement.src = URL.createObjectURL(webmFile);
  
      tempVideoElement.addEventListener("loadedmetadata", () => {
        const duration = tempVideoElement.duration;

        uploadBytes(webmRef, webmFile).then((webmSnapshot) => {
          console.log("WebM Video uploaded!");

          uploadBytes(mp4Ref, mp4File).then((mp4Snapshot) => {
            console.log("MP4 Video uploaded!");
          
            getDownloadURL(webmSnapshot.ref).then((downloadURLWebm) => {
              getDownloadURL(mp4Snapshot.ref).then((downloadURLMp4) => {
            
                const thumbnailRef = ref(storage, `thumbnails/${thumbnailFile.name}`);
                uploadBytes(thumbnailRef, thumbnailFile).then((snapshot) => {
                  console.log("Thumbnail uploaded!");
                  getDownloadURL(snapshot.ref).then((downloadURLThumbnail) => {
                    console.log(downloadURLThumbnail);
      
                    addNewVideoToDB(
                      replaceUnderscores(blockchain_name),
                      duration,
                      [],
                      replaceUnderscores(journey_type),
                      webmFile.name,
                      replaceUnderscores(journey_name),
                      platform,
                      replaceUnderscores(project_name),
                      getUnixTimestamp(webmFile),
                      downloadURLWebm,
                      downloadURLMp4,
                      downloadURLThumbnail,
                      version,
                      videoTimestamps
                    );
                  });
                });
              });
            });
          });
        });
      });
    }
  };
  */

  // Helper function to upload a file and return its download URL
const uploadAndGetDownloadURL = async (storageRef, file) => {
  const snapshot = await uploadBytes(storageRef, file);
  console.log(`${file.name} uploaded!`);
  return getDownloadURL(snapshot.ref);
};

// Helper function to filter files by type
const filterFilesByType = (filesArray, type) => {
  return filesArray.filter((file) => file.type.startsWith(type));
};

// Helper function to filter files by extension
const filterFilesByExtension = (filesArray, extension) => {
  return filesArray.filter((file) => file.name.endsWith(extension));
};

// Main function
const uploadVideosBulk = async (files) => {
  const filesArray = Array.from(files);
  
  // Filter video and cover image files
  const videoFiles = filterFilesByType(filesArray, 'video/');
  const thumbnailFiles = filterFilesByType(filesArray, 'image/');
  const jsonFiles = filterFilesByType(filesArray, 'application/json');

  // Split video files by format
  const webmFiles = filterFilesByExtension(videoFiles, '.webm');
  const mp4Files = filterFilesByExtension(videoFiles, '.mp4');
  
  if (webmFiles.length !== mp4Files.length) {
    console.error('Number of WebM files does not match number of MP4 files.');
    return;
  }

  for (let i = 0; i < webmFiles.length; i++) {
    const webmFile = webmFiles[i];
    const mp4File = mp4Files[i];

    // Remove the file extension to match the thumbnail file name
    const videoFileNameWithoutExtension = webmFile.name.split('.').slice(0, -1).join('.');

    // Find the corresponding thumbnail file based on the file name
    const thumbnailFile = thumbnailFiles.find((imageFile) =>
      imageFile.name.startsWith(videoFileNameWithoutExtension)
    );
    if (!thumbnailFile) {
      console.error(`Thumbnail not found for ${webmFile.name}`);
      continue;
    }

    const jsonFile = jsonFiles.find((imageFile) =>
      imageFile.name.startsWith(videoFileNameWithoutExtension)
    );
    if (!jsonFile) {
      console.error(`JSON not found for ${webmFile.name}`);
      continue;
    }

    let videoTimestamps = [];
    if (jsonFile) {
      const jsonContent = await jsonFile.text();
      const jsonData = JSON.parse(jsonContent);
      videoTimestamps = jsonData.videoTimestamps;
      console.log(videoTimestamps);
    }

    const [
      project_name,
      journey_type,
      journey_name,
      blockchain_name,
      platform,
      version,
      timestamp
    ] = videoFileNameWithoutExtension.split('-');
  
    const webmRef = ref(storage, `videos/${webmFile.name}`);
    const mp4Ref = ref(storage, `videos/${mp4File.name}`);
    const thumbnailRef = ref(storage, `thumbnails/${thumbnailFile.name}`);

    const tempVideoElement = document.createElement("video");
    tempVideoElement.src = URL.createObjectURL(webmFile);
  
    tempVideoElement.addEventListener("loadedmetadata", async () => {
      const duration = tempVideoElement.duration;
      const downloadURLWebm = await uploadAndGetDownloadURL(webmRef, webmFile);
      const downloadURLMp4 = await uploadAndGetDownloadURL(mp4Ref, mp4File);
      const downloadURLThumbnail = await uploadAndGetDownloadURL(thumbnailRef, thumbnailFile);

      addNewVideoToDB(
        replaceUnderscores(blockchain_name),
        duration,
        [],
        replaceUnderscores(journey_type),
        webmFile.name,
        replaceUnderscores(journey_name),
        platform,
        replaceUnderscores(project_name),
        getUnixTimestamp(webmFile),
        downloadURLWebm,
        downloadURLMp4,
        downloadURLThumbnail,
        version,
        videoTimestamps
      );
    });
  }
};

  
  const uploadScreenshot = (file, name, data) => {
  const screenshotsRef = ref(storage, `images/${name}`);
  uploadBytes(screenshotsRef, file).then((snapshot) => {
    console.log('Screenshot uploaded!');
    getDownloadURL(snapshot.ref).then((downloadURL) => {
      console.log(downloadURL);
      addNewScreenshotToDB(data.blockchain, data.elements, data.journey, name, data.screenName, data.platform, data.project, data.urlJpg.lastModified, downloadURL, data.version)
    });
  });
  }

  const bulkUploadScreenshots = async (files) => {
    const results = [];

    for (const file of files) {
      const filename = file.name;
      const storageRef = ref(storage, `images/${filename}`);
      try {
        await uploadBytes(storageRef, file);
        console.log("Upload successful:", filename);

        const [
          project_name,
          journey_type,
          journey_name,
          screen_name,
          blockchain_name,
          platform,
          version,
          timestamp
        ] = filename.split("-");

        const url_webp = await getDownloadURL(storageRef);

        const fileData = {
          blockchain: blockchain_name,
          journey: replaceUnderscores(journey_type),
          journeyName: replaceUnderscores(journey_name),
          name: filename,
          platform: platform,
          project: replaceUnderscores(project_name),
          screenName: replaceUnderscores(screen_name),
          timeStamp: getUnixTimestamp(file),
          urlJpg: url_webp,
          version: version,
          updatedAt: serverTimestamp()
        };

        const docRef = await addDoc(collectionScreenshots, fileData);
        results.push({id: docRef.id, ...fileData});
      } catch (error) {
        console.log("Upload error:", error);
      }
    }
    return results;
  }

  // BLOG

  const uploadPostImage = async (data) => {
    console.log(data);
    const imageRef = ref(storage, `blogPostImages/${data.name}`);
    uploadBytes(imageRef, data).then((snapshot) => {
      console.log('New image added to DB!');
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        console.log(downloadURL);
        return downloadURL;
      })
    })
  }

  const uploadImage = async (data) => {
    const imageRef = ref(storage, `blogPostImages/${data.name}`);
    uploadBytes(imageRef, data).then((snapshot) => {
      console.log('New image added to DB!');
    })
    return imageRef;
  }

  const addNewPostToDB = async (
    docName,
    author,
    body,
    cover,
    createdAt,
    description,
    id,
    metaDescription,
    metaKeywords,
    slug,
    tags,
    title
    ) => {
    await setDoc(doc(DB, 'blogposts', docName), {
      author,
      body,
      cover,
      createdAt,
      description,
      id,
      metaDescription,
      metaKeywords,
      slug,
      tags,
      title,
    })
    .then(() => {
      alert('New post added to DB')
    })
    .catch((err) => {
      alert(err.message)
    });
  };

  const addIdsToH2Tags = (htmlContent) => {
    // Load the HTML content using cheerio
    const $ = cheerio.load(htmlContent);

    // Select all h2 tags and add id attribute
    $('h2').each((index, element) => {
        let text = $(element).text();
        console.log('text:', text); // print h2 text
        // Convert text to lowercase, replace spaces with dashes
        let id = text.toLowerCase().split(' ').join('-');
        console.log('id:', id); // print generated id
        // Add id to h2 tag
        $(element).attr('id', id);
    });

    // Return the updated HTML content
    return $.html();
};

  const createNewPost = async (data) => {
    // upload cover
    const coverRef = ref(storage, `blogPostCovers/${data.cover.name}`);
    uploadBytes(coverRef, data.cover).then((snapshot) => {
      console.log('Cover uploaded!');
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        console.log(downloadURL);
        let contentWithIds = addIdsToH2Tags(data.content);
        // create new doc in blogposts collection
        addNewPostToDB(
          paramCase(data.title),
          data.author,
          contentWithIds,
          downloadURL,
          Date.now(), // createdAt
          data.description,
          uuid(),
          data.metaDescription,
          data.metaKeywords,
          paramCase(data.title),
          data.tags,
          data.title)
        .then(() => {
          alert('New blog post added to DB')
        })
        .catch((err) => {
          alert(err.message)
        });
      });
    });
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        login,
        loginWithGoogle,
        loginWithGithub,
        loginWithTwitter,
        register,
        logout,

        addNewProjectToDB,
        addNewScreenshotToDB,
        addNewDiagramToDB,

        uploadPostImage,
        createNewPost,
        createBlockchainInDB,

        uploadImage,
        getDownloadURL,
        bulkUploadScreenshots,
        uploadVideosBulk,
        
        getDataFromDB,
        getDocumentFromDB,
        queryDataFromDB,
        uploadLogo,
        uploadVideo,
        uploadScreenshot,
        getImageUrlFromDB,

        // UPDATE
        updateCounters,
        updateProjectBoard,
        updateScreenshotOrder,
        updateProductDetailsInDB,
        updateVideoDetailsInDB,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

