import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logoColour = '#FFFFFF' // #42B5EF

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 200,
        height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 796 53">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H44C52.8366 0 60 7.16357 60 16C60 18.8179 59.2715 21.4653 57.9926 23.7651C61.6614 26.7915 64 31.3726 64 36.5C64 45.4453 56.8814 52.7285 48 52.9927V53H0V0ZM48 32H10V42H48C50.7614 42 53 39.7612 53 37C53 34.2388 50.7614 32 48 32ZM10 11H45C47.7614 11 50 13.2388 50 16C50 18.7612 47.7614 21 45 21H10V11Z" fill={logoColour} />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M664 0H708C716.837 0 724 7.16357 724 16C724 18.8179 723.272 21.4653 721.993 23.7651C725.661 26.7915 728 31.3726 728 36.5C728 45.4453 720.881 52.7285 712 52.9927V53H664V0ZM712 32H674V42H712C714.761 42 717 39.7612 717 37C717 34.2388 714.761 32 712 32ZM674 11H709C711.761 11 714 13.2388 714 16C714 18.7612 711.761 21 709 21H674V11Z" fill={logoColour} />
        <path d="M69 0V53H170V42H80V32H147V21H80V11H170V0H69Z" fill={logoColour} />
        <path d="M558 0V53H659V42H569V32H636V21H569V11H659V0H558Z" fill={logoColour} />
        <path d="M248 16C248 7.16357 240.837 0 232 0H189C180.163 0 173 7.16357 173 16C173 24.8364 180.163 32 189 32H232C234.761 32 237 34.2388 237 37C237 39.7612 234.761 42 232 42H189C186.239 42 184 39.7612 184 37H173C173 45.8364 180.163 53 189 53H232C240.837 53 248 45.8364 248 37C248 28.1636 240.837 21 232 21H189C186.239 21 184 18.7612 184 16C184 13.2388 186.239 11 189 11H232C234.761 11 237 13.2388 237 16H248Z" fill={logoColour} />
        <path d="M320 0H250V11H280V53H291V11H320V0Z" fill={logoColour} />
        <path d="M322 0H354V11H343V42H354V53H322V42H332V11H322V0Z" fill={logoColour} fill-opacity="0.6" />
        <path d="M359 0H370L426 40V0H437V53H426L370 13V53H359V0Z" fill={logoColour} fill-opacity="0.6" />
        <path d="M452 0H441L466 53H478L498 11L517 53H529L554 0H543L523 42L503 0H492L472 42L452 0Z" fill={logoColour} />
        <path d="M735 16C735 7.16357 742.163 0 751 0H776C784.837 0 792 7.16357 792 16C792 19.0278 791.159 21.8589 789.698 24.2729C793.528 27.1963 796 31.8096 796 37C796 45.8364 788.837 53 780 53H747C738.163 53 731 45.8364 731 37H742C742 39.7612 744.239 42 747 42H780C782.761 42 785 39.7612 785 37C785 34.2388 782.761 32 780 32H753V21H776C778.761 21 781 18.7612 781 16C781 13.2388 778.761 11 776 11H751C748.239 11 746 13.2388 746 16H735Z" fill={logoColour} />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
