import keyBy from 'lodash/keyBy';
import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

let boardMock = {
  "board": {
    "cards": [
      { // card.id: card object
        "id": "95",
        "name": "Card Name 95", // Reference the screenshotName
        "attachments": [], // url to image
      }, 
      { // card.id: card object
        "id": "96",
        "name": "Card Name 96", // Reference the screenshotName
        "attachments": [], // url to image
      }, 
      { // card.id: card object
        "id": "97",
        "name": "Card Name 97", // Reference the screenshotName
        "attachments": [], // url to image
      }, 
      { // card.id: card object
        "id": "98",
        "name": "Card Name 98", // Reference the screenshotName
        "attachments": [], // url to image
      }, 
    ],
    "columns": [
      { // column.id: column object
        "id": "23",
        "cardIds": ["95", "96"], // ordered list of card ids
        "name": "Column Name",
      }, 
      { // column.id: column object
        "id": "24",
        "cardIds": ["97", "98"], // ordered list of card ids
        "name": "Column Name",
      }, 
    ],
    "columnOrder": ["23", "24"] // ordered list of {columnId: 123, index: 123}
  }
};

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  board: {
    cards: {},
    columns: {},
    columnOrder: [],
  },
};

const slice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BOARD
    getBoardSuccess(state, action) {
      state.isLoading = false;
      const board = action.payload;
      const cards = keyBy(board.cards, 'id');
      const columns = keyBy(board.columns, 'id');
      const { columnOrder } = board;

      state.board = {
        cards,
        columns,
        columnOrder,
      };
    },

    // CREATE NEW COLUMN
    createColumnSuccess(state, action) {
      const newColumn = action.payload;
      state.isLoading = false;
      state.board.columns = {
        ...state.board.columns,
        [newColumn.id]: newColumn,
      };
      state.board.columnOrder.push(newColumn.id);
    },

    persistCard(state, action) {
      const columns = action.payload;
      state.board.columns = columns;
    },

    persistColumn(state, action) {
      state.board.columnOrder = action.payload;
    },

    addTask(state, action) {
      const { card, columnId } = action.payload;

      state.board.cards[card.id] = card;
      state.board.columns[columnId].cardIds.push(card.id);
    },

    deleteTask(state, action) {
      const { cardId, columnId } = action.payload;

      state.board.columns[columnId].cardIds = state.board.columns[columnId].cardIds.filter((id) => id !== cardId);

      state.board.cards = omit(state.board.cards, [cardId]);
    },

    // UPDATE COLUMN
    updateColumnSuccess(state, action) {
      const column = action.payload;

      state.isLoading = false;
      state.board.columns[column.id] = column;
    },

    // DELETE COLUMN
    deleteColumnSuccess(state, action) {
      const { columnId } = action.payload;
      const deletedColumn = state.board.columns[columnId];

      state.isLoading = false;
      state.board.columns = omit(state.board.columns, [columnId]);
      state.board.cards = omit(state.board.cards, [...deletedColumn.cardIds]);
      state.board.columnOrder = state.board.columnOrder.filter((c) => c !== columnId);
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getBoard(productName) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      //const response = await axios.get('/api/kanban/board');
      const response = boardMock;
      //dispatch(slice.actions.getBoardSuccess(response.data.board));
      dispatch(slice.actions.getBoardSuccess(boardMock.board));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateBoard(board) {
  return async () => {
    try {
      dispatch(slice.actions.getBoardSuccess(board));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createColumn(newColumn) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      //const response = await axios.post('/api/kanban/columns/new', newColumn);
      //dispatch(slice.actions.createColumnSuccess(response.data.column));
      dispatch(slice.actions.createColumnSuccess(newColumn));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateColumn(columnId, updateColumn) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.updateColumnSuccess(updateColumn));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteColumn(columnId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.deleteColumnSuccess({ columnId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function persistColumn(newColumnOrder) {
  return () => {
    dispatch(slice.actions.persistColumn(newColumnOrder));
  };
}

// ----------------------------------------------------------------------

export function persistCard(columns) {
  return () => {
    dispatch(slice.actions.persistCard(columns));
  };
}

// ----------------------------------------------------------------------

export function addTask({ card, columnId }) {
  return () => {
    dispatch(slice.actions.addTask({ card, columnId }));
  };
}

// ----------------------------------------------------------------------

export function deleteTask({ cardId, columnId }) {
  return () => {
    dispatch(slice.actions.deleteTask({ cardId, columnId }));
  };
}
