// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

const navConfig = [

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // USER
      {
        title: 'users',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'user list', path: PATH_DASHBOARD.user.profile },
          { title: 'new user', path: PATH_DASHBOARD.user.cards },
        ],
      },

      // PRODUCTS
      {
        title: 'products',
        path: PATH_DASHBOARD.product.root,
        icon: ICONS.cart,
        children: [
          { title: 'product list', path: PATH_DASHBOARD.product.productList },
          { title: 'new product', path: PATH_DASHBOARD.product.newProduct },
          { title: 'add screenshot', path: PATH_DASHBOARD.product.addScreenshot },
          { title: 'add multiple screenshots', path: PATH_DASHBOARD.product.addMultipleScreenshots },
          { title: 'add video', path: PATH_DASHBOARD.product.addVideo },
          { title: 'add multiple videos', path: PATH_DASHBOARD.product.addMultipleVideos },
          { title: 'add diagram', path: PATH_DASHBOARD.product.addDiagram },
        ],
      },

      // BLOG
      {
        title: 'blog',
        path: PATH_DASHBOARD.blog.root,
        icon: ICONS.blog,
        children: [
          { title: 'authors', path: PATH_DASHBOARD.blog.posts },
          { title: 'tags', path: PATH_DASHBOARD.blog.posts },
          { title: 'posts', path: PATH_DASHBOARD.blog.posts },
          { title: 'create post', path: PATH_DASHBOARD.blog.new },
        ],
      },

    ],
  },
];

export default navConfig;
