import { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import '../../utils/highlight';
import ReactQuill from 'react-quill';
//
import { StyledEditor } from './styles';
import EditorToolbar, { formats } from './EditorToolbar';
import { useAuthContext } from '../../auth/useAuthContext';


// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

Editor.propTypes = {
  id: PropTypes.string,
  sx: PropTypes.object,
  error: PropTypes.bool,
  simple: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  helperText: PropTypes.object,
};

export default function Editor({
  id = 'minimal-quill',
  error,
  value,
  onChange,
  simple = false,
  helperText,
  sx,
  ...other
}) {

  const { uploadPostImage, getDownloadURL, uploadImage } = useAuthContext();

  const quillRef = useRef();

  const imageHandler = () => {
    const input = document.createElement('input');
    
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    
    const editor = quillRef.current.getEditor()
    
    input.onchange = async () => {
      const file = input.files[0];
      console.log(file);
      const formData = new FormData();

      formData.append('image', file);

      // Save current cursor state
      const range = editor.getSelection(true);

      // Insert temporary loading placeholder image
      editor.insertEmbed(range.index, 'image', `$/assets/images/portraits/portrait_1.png`); 

      // Move cursor to right side of image (easier to continue typing)
      editor.setSelection(range.index + 1);

      //const res = await uploadPostImage(file); // API post, returns image location as string e.g. 'http://www.example.com/images/foo.png'
      //console.log("This is res")
      //console.log(res)

      console.log("I got here A");

      const reference = await uploadImage(file);
      console.log(reference);
      console.log("I got here B");
      // Remove placeholder image
      editor.deleteText(range.index, 1);
      console.log("I got here C");
      getDownloadURL(reference).then(async (downloadURL) => {
        // Insert uploaded image
        console.log("I got here D");
        editor.insertEmbed(range.index, 'image', await downloadURL);
        console.log("I got here E");
      })


    }
  }

  const modules = useMemo(() => ({
    toolbar: {
      container: `#${id}`,
      handlers: {
        image: imageHandler
      }
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    syntax: true,
    clipboard: {
      matchVisual: false,
    },
  }),[]);

  return (
    <>
      <StyledEditor
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
          }),
          ...sx,
        }}
      >
        <EditorToolbar id={id} isSimple={simple} />

        <ReactQuill
          ref={quillRef}
          value={value}
          onChange={onChange}
          modules={modules}
          formats={formats}
          placeholder="Write something awesome..."
          {...other}
        />
      </StyledEditor>

      {helperText && helperText}
    </>
  );
}
